<template>
  <div class="page page__articles articles">
    <div class="articles__content">
      <esmp-tabs value="actual" @on-click="setTab">
        <esmp-tab-pane
          label="Активные статьи"
          name="actual"
        />
        <esmp-tab-pane
          label="Архивные статьи"
          name="archive"
        />
      </esmp-tabs>
      <div v-if="mappedArticles && mappedArticles.length" class="articles__list tile-grid">
        <faq-article-item
          v-for="article in mappedArticles"
          :key="article.id"
          :image-url="article.mainImageUrl"
          :title="article.title"
          :additional-info="article.additionalInfo"
          :url="article.url"
        />
      </div>
      <div v-else>
        Нет записей
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FaqArticleItem } from '@/components/faq';

export default {
  name: 'Articles',
  components: {
    FaqArticleItem,
  },
  data() {
    return {
      activeTab: 'actual',
    };
  },
  computed: {
    ...mapGetters('articles', ['mappedArticles']),
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('articles', ['getArticles']),
    setTab(activeTab) {
      this.activeTab = activeTab;
      // TODO: Endpoint еще не реализован
      // this.getArticlesList();
    },
    getArticlesList() {
      this.setLoading({ key: 'page', value: true });
      this.getArticles({
        isPublic: true,
        state: this.activeTab,
      })
        .finally(() => this.setLoading({ key: 'page', value: false }));
    },
  },
  created() {
    // TODO: Endpoint еще не реализован
    // if (!this.mappedArticles.length) this.getArticlesList();
  },
};
</script>

<style lang="scss">
  .articles {
    .tile-grid {
      grid-template-columns: 1fr 1fr 1fr;

      @include for-size(phone-portrait-down) {
        grid-template-columns: 1fr;
      }
    }
  }
</style>
